import * as React from "react";

import CSS from "csstype";
import {
  itemTitleStyles,
  linkContainerStyles,
  linkStyles,
  techStackStyles,
  underlineStyles,
} from "../../sectionItemStyles";

const jobTitleAndDurationContainerStyles: CSS.Properties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
};

const jobTitleStyles: CSS.Properties = {
  fontSize: "1rem",
  fontWeight: 400,
  textTransform: "uppercase",
};

const durationStyles: CSS.Properties = {
  fontSize: "0.8rem",
  fontWeight: 500,
  fontStyle: "italic",
};

const locationStyles: CSS.Properties = {
  fontSize: "1rem",
  fontWeight: 200,
  width: "100%",
  display: "flex",
  marginTop: 0,
};

const captionStyles: CSS.Properties = {
  fontSize: "1rem",
  fontStyle: "italic",
  fontWeight: 400,
  width: "100%",
  display: "flex",
  marginTop: 0,
};

const responsibilitiesContainerStyles: CSS.Properties = {
  width: "100%",
};

const responsibilitiesTitleStyles: CSS.Properties = {
  fontSize: "1rem",
  fontWeight: 400,
  width: "100%",
  display: "flex",
  marginTop: 0,
};

const responsibilitiesItemStyles: CSS.Properties = {
  fontSize: "0.9rem",
  fontWeight: 400,
  textAlign: "left",
};

function WorkItem(props: {
  companyName: string;
  jobTitle: string;
  location: string;
  caption: string;
  duration: string;
  responsibilities: string[];
  techStack: string;
  link: { text: string; url: string };
}) {
  return (
    <>
      <h2 style={{ ...itemTitleStyles, marginBottom: 0 }}>
        {props.companyName}
      </h2>
      <hr style={underlineStyles} />
      <div style={jobTitleAndDurationContainerStyles}>
        <h4 style={jobTitleStyles}>{props.jobTitle}</h4>
        <h4 style={durationStyles}>{props.duration}</h4>
      </div>
      <h4 style={locationStyles}>{props.location}</h4>
      <h4 style={captionStyles}>{props.caption}</h4>
      <div style={responsibilitiesContainerStyles}>
        <h4 style={responsibilitiesTitleStyles}>Responsibilities:</h4>
        <ul>
          {props.responsibilities.map((r, idx) => (
            <li key={idx}>
              <h4 style={responsibilitiesItemStyles}>{r}</h4>
            </li>
          ))}
        </ul>
      </div>
      <h4 style={techStackStyles}>Tech Stack: {props.techStack}</h4>
      <div style={linkContainerStyles}>
        <h4 style={{ fontWeight: 400 }}>Link:</h4>
        <a href={props.link.url} style={linkStyles}>
          {props.link.text}
        </a>
      </div>
    </>
  );
}

export default function WorkHistory() {
  return (
    <>
      <WorkItem
        companyName={"Skyral"}
        jobTitle={"Software engineer"}
        location={"London, UK"}
        duration={"~1yr"}
        caption={
          "Operational planning tools for large scale government and enterprise projects."
        }
        responsibilities={[
          "Building advanced mapping solutions to visualise spatial data.",
          "Developing distributed event driven systems for real-time data processing.",
          "Maintaining and improving the company wide component library.",
        ]}
        techStack={
          "React, TypeScript, Node, Python, Go, PostgreSQL, ArangoDB, Kafka, Docker, GCP, K8s, Storybook."
        }
        link={{ text: "skyral.com", url: "https://www.skyral.com/" }}
      />
      <WorkItem
        companyName={"Five AI"}
        jobTitle={"Senior software engineer"}
        location={"London, UK"}
        duration={"2yrs, 10mo"}
        caption={"Cloud based simulation software for self-driving cars."}
        responsibilities={[
          "Building the platform web UI for the execution, validation and visual analysis of autonomous vehicle test runs.",
          "Leading the development of a GraphQL API to unify many microservices into a single streamlined server.",
        ]}
        techStack={
          "React, TypeScript, Python, GraphQL, Terraform, GitLab CI/CD, Docker, AWS, Cypress."
        }
        link={{ text: "five.ai", url: "https://www.five.ai/" }}
      />
      <WorkItem
        companyName={"Sky"}
        jobTitle={"Software engineer"}
        location={"London, UK"}
        duration={"2yrs, 8mo"}
        caption={"Media, telecoms & broadcasting."}
        responsibilities={[
          "Developing web based applications used by the TV production team to control graphics and data visuals for live Sky Sports and Sky News channels.",
          "Building control and monitoring tools for broadcast equipment and transmission devices.",
        ]}
        techStack={"C#, .NET, React, JavaScript, TypeScript, SQL Server."}
        link={{ text: "sky.com", url: "https://www.sky.com/" }}
      />
    </>
  );
}
