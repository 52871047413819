import { useCallback, useEffect, useState } from "react";

const intersectionObserverOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: "0px 0px -50% 0px",
  threshold: 0.5,
};

export default function useSectionInView(ref: React.RefObject<HTMLDivElement>) {
  const [inView, setInView] = useState(false);

  const handleInteract = useCallback((entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setInView(entry.isIntersecting);
  }, []);

  useEffect(() => {
    const refCurrent = ref.current;
    const observer = new IntersectionObserver(
      handleInteract,
      intersectionObserverOptions
    );
    if (refCurrent) observer.observe(refCurrent);
    return () => {
      if (refCurrent) observer.unobserve(refCurrent);
    };
  }, [handleInteract, ref]);

  return { inView };
}
