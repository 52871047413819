import CSS from "csstype";

import { COLORS } from "./styles";

export const itemTitleStyles: CSS.Properties = {
  fontSize: "1.33rem",
  fontWeight: 500,
  width: "100%",
  display: "flex",
};

export const underlineStyles: CSS.Properties = {
  height: "1",
  backgroundColor: COLORS.purple,
  width: "100%",
};

export const techStackStyles: CSS.Properties = {
  fontSize: "1rem",
  fontWeight: 400,
  width: "100%",
  display: "flex",
};

export const linkContainerStyles: CSS.Properties = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: "1rem",
  fontWeight: 400,
};

export const linkStyles: CSS.Properties = {
  marginLeft: "8px",
  textDecoration: "none",
  color: COLORS.purple,
};
