import React, { useEffect, useRef, useState } from "react";

import Contact from "./components/sections/Contact";
import Intro from "./components/sections/Intro";
import Projects from "./components/sections/Projects";
import Skills from "./components/sections/Skills";
import WorkHistory from "./components/sections/WorkHistory";

import appLogo from "./images/app-logo.svg";
import CSS from "csstype";
import { COLORS, SIZES } from "./styles";
import NavButton from "./components/NavButton";
import Footer from "./components/Footer";
import "./animate.css";
import useSectionInView from "./hooks/useSectionInView";
import useIsMobile from "./hooks/useIsMobile";
import MobileNav from "./components/MobileNav";

const headerStyles: CSS.Properties = {
  display: "flex",
  justifyContent: "space-around",
  top: 0,
  position: "sticky",
  backgroundColor: "white",
};

const navStyles: CSS.Properties = {
  flexDirection: "row",
  display: "flex",
  marginTop: "32px",
};

const pageContainerStyles: CSS.Properties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginRight: "10%",
  marginLeft: "10%",
};

const sectionTitleStyle: CSS.Properties = {
  fontSize: "1.5rem",
  textTransform: "uppercase",
  fontWeight: 500,
};

const sectionDividerStyles: CSS.Properties = {
  backgroundColor: COLORS.purple,
  width: "50%",
  height: "2px",
  marginTop: "3rem",
  marginBottom: "3rem",
};

export interface Section {
  name: string;
  ref: React.RefObject<HTMLDivElement>;
}

function App() {
  const [activeSection, setActiveSection] = useState("Intro");
  const { isMobile } = useIsMobile();

  //scrollToRefs
  const introRef = useRef<HTMLDivElement>(null);
  const skillsRef = useRef<HTMLDivElement>(null);
  const workHistoryRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);
  const contactsRef = useRef<HTMLDivElement>(null);

  const sections: Section[] = [
    { name: "Intro", ref: introRef },
    { name: "Skills", ref: skillsRef },
    { name: "Work History", ref: workHistoryRef },
    { name: "Projects", ref: projectsRef },
    { name: "Contact", ref: contactsRef },
  ];

  const { inView: introInView } = useSectionInView(introRef);
  const { inView: skillsInView } = useSectionInView(skillsRef);
  const { inView: workHistoryInView } = useSectionInView(workHistoryRef);
  const { inView: projectsInView } = useSectionInView(projectsRef);
  const { inView: contactsInView } = useSectionInView(contactsRef);

  useEffect(() => {
    if (introInView) setActiveSection("Intro");
    if (skillsInView) setActiveSection("Skills");
    if (workHistoryInView) setActiveSection("Work History");
    if (projectsInView) setActiveSection("Projects");
    if (contactsInView) setActiveSection("Contact");
  }, [
    introInView,
    skillsInView,
    workHistoryInView,
    projectsInView,
    contactsInView,
  ]);

  const scrollTo = (element: React.RefObject<HTMLDivElement>) => {
    if (element.current) {
      const elementPosition = element.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - SIZES.appLogo;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      <header style={headerStyles} className={"slide"}>
        <img
          src={appLogo}
          alt="Logo"
          height={SIZES.appLogo}
          width={SIZES.appLogo}
        />
        <nav style={navStyles}>
          {isMobile ? (
            <MobileNav
              sections={sections}
              onNavButtonClick={(sectionName) => {
                setActiveSection(sectionName);
                scrollTo(sections.find((s) => s.name === sectionName)?.ref!);
              }}
              activeSection={activeSection}
            />
          ) : (
            sections.map((section) => (
              <div
                key={section.name}
                onClick={() => {
                  setActiveSection(section.name);
                  scrollTo(section.ref);
                }}
              >
                <NavButton
                  section={section.name}
                  isActive={activeSection === section.name}
                />
              </div>
            ))
          )}
        </nav>
      </header>

      <div style={pageContainerStyles}>
        <div ref={introRef} style={{ height: "80vh" }}>
          <Intro />
        </div>
        <hr style={sectionDividerStyles} />

        <h1 ref={skillsRef} style={sectionTitleStyle}>
          Skills
        </h1>
        <Skills />
        <hr style={sectionDividerStyles} />

        <h1 ref={workHistoryRef} style={sectionTitleStyle}>
          Work History
        </h1>
        <WorkHistory />
        <hr style={sectionDividerStyles} />

        <h1 ref={projectsRef} style={sectionTitleStyle}>
          Personal Projects
        </h1>
        <Projects />
        <hr style={sectionDividerStyles} />

        <h1 ref={contactsRef} style={sectionTitleStyle}>
          Contact
        </h1>
        <Contact />
        <hr style={sectionDividerStyles} />
      </div>

      <Footer />
    </div>
  );
}

export default App;
