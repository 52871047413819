import * as React from "react";
import memoji from "../images/memoji-footer.png";

import CSS from "csstype";

const footerStyles: CSS.Properties = {
  display: "flex",
  maxWidth: "100%",
  justifyContent: "center",
  marginBottom: "128px",
  alignItems: "center",
  flexDirection: "column",
};

const memojiStyles: CSS.Properties = {
  height: "72px",
  borderRadius: "200px",
};

export default function Footer() {
  return (
    <footer style={footerStyles}>
      <p>
        <span>&copy;</span> 2023 Divine Built This. All rights reserved.
      </p>

      <div>
        <img src={memoji} style={memojiStyles} alt="Divine Memoji Footer" />
      </div>
    </footer>
  );
}
