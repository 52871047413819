import CSS from "csstype";
import { useState } from "react";
import { Section } from "../App";
import { COLORS } from "../styles";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const menuIcon: CSS.Properties = {
  width: "24px",
  height: "2px",
  backgroundColor: COLORS.purple,
  margin: "3px 0",
  cursor: "pointer",
};

const menuList: CSS.Properties = {
  padding: "8px",
  marginTop: "24px",
};

const menuListItem: CSS.Properties = {
  marginLeft: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  fontWeight: 500,
  cursor: "pointer",
};

export default function MobileNav(props: {
  sections: Section[];
  activeSection: string;
  onNavButtonClick: (sectionName: string) => void;
}) {
  const { sections, activeSection, onNavButtonClick } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleListItemClick = (sectionName: string) => {
    setIsMenuOpen(false);
    onNavButtonClick(sectionName);
  };

  return (
    <>
      <div onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div style={menuIcon} />
        <div style={menuIcon} />
        <div style={menuIcon} />
      </div>

      <Drawer
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        direction="right"
        size={200}
      >
        <div style={menuList}>
          {sections.map((section) => (
            <div
              key={section.name}
              style={{
                ...menuListItem,
                color:
                  activeSection === section.name ? COLORS.purple : undefined,
              }}
              onClick={(e) => handleListItemClick(section.name)}
            >
              {section.name}
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
}
