import * as React from "react";
import CSS from "csstype";

const skillTextBoldStyle: CSS.Properties = { fontWeight: 600 };

const skillTextStyle: CSS.Properties = {
  fontSize: "1em",
  fontWeight: 100,
};

export default function Skills() {
  return (
    <>
      <h3 style={skillTextStyle}>
        <span style={skillTextBoldStyle}>I speak </span>JavaScript, TypeScript,
        Python, C#, Go, HTML & CSS
      </h3>
      <h3 style={skillTextStyle}>
        <span style={skillTextBoldStyle}>backed by </span>React, NodeJS,
        ExpressJS, NestJS, FastAPI
      </h3>
      <h3 style={skillTextStyle}>
        <span style={skillTextBoldStyle}>and deploy via </span> Docker,
        Terraform, AWS, GCP, GitLab CI/CD
      </h3>
      <h3 style={skillTextStyle}>
        <span style={skillTextBoldStyle}>apps beautifully designed with </span>{" "}
        Figma, Canva, Sketch
      </h3>
      <h3 style={skillTextStyle}>
        <span style={skillTextBoldStyle}>with a little help from </span> Git,
        GraphQL, PostgreSQL, Unity, Storybook.
      </h3>
    </>
  );
}
