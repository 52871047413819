import * as React from "react";
import contactCard from "../../images/contact-card.png";
import twitterIcon from "../../images/twitter-icon.svg";
import emailIcon from "../../images/email-icon.svg";
import CSS from "csstype";

const cardStyles: CSS.Properties = {
  width: "80vw",
  maxWidth: "700px",
};

const iconContainerStyles: CSS.Properties = {
  display: "flex",
  justifyContent: "center",
  marginTop: "40px",
};

const iconStyles: CSS.Properties = {
  height: "32px",
  margin: "8px",
};

export default function Contact() {
  return (
    <div>
      <img src={contactCard} style={cardStyles} alt="Contact Card" />
      <div style={iconContainerStyles}>
        <a href="https://twitter.com/divinebuiltthis">
          <img
            src={twitterIcon}
            style={iconStyles}
            alt="Twitter @divinebuiltthis"
          />
        </a>
        <a href="mailto:divine@divinebuiltthis.com">
          <img
            src={emailIcon}
            style={iconStyles}
            alt="mailto: divine@divinebuiltthis.com"
          />
        </a>
      </div>
    </div>
  );
}
