import * as React from "react";
import {
  itemTitleStyles,
  linkContainerStyles,
  linkStyles,
  techStackStyles,
  underlineStyles,
} from "../../sectionItemStyles";
import CSS from "csstype";

import storymapLogo from "../../images/storymap/storymap-logo.png";
import storymapLogin from "../../images/storymap/storymap-login.png";
import storymapHome from "../../images/storymap/storymap-home.png";
import storymapNotifs from "../../images/storymap/storymap-notifs.png";
import storymapComments from "../../images/storymap/storymap-comments.png";

import sirclesVideo from "../../video/sircles-preview.mp4";
import sirclesLogo from "../../images/sircles/sircles-logo.png";
import sirclesHome from "../../images/sircles/sircles-home.png";
import sirclesGameplay from "../../images/sircles/sircles-gameplay.png";
import sirclesBoosts from "../../images/sircles/sircles-boosts.png";

const logoAndTitleContainerStyles: CSS.Properties = {
  display: "flex",
  alignItems: "center",
  marginBottom: "-24px",
  width: "100%",
};

const logoStyles: CSS.Properties = {
  width: "1.5rem",
  height: "1.5rem",
  marginRight: "8px",
};

const descriptionStyles: CSS.Properties = {
  fontSize: "1em",
  width: "100%",
  display: "flex",
  fontWeight: 400,
};

const imageContainerStyles: CSS.Properties = {
  width: "80vw",
  display: "flex",
  justifyContent: "space-between",
  overflow: "scroll",
};

const imageStyles: CSS.Properties = {
  height: "480px",
  border: "1px solid white",
};

function Project(props: {
  name: string;
  logoUrl: string;
  imageUrls: string[];
  videoUrl?: string;
  description: string[];
  techStack: string;
  links: { text: string; url: string }[];
}) {
  return (
    <>
      <div style={logoAndTitleContainerStyles}>
        <img style={logoStyles} src={props.logoUrl} alt={props.name} />
        <h2 style={itemTitleStyles}>{props.name}</h2>
      </div>
      <hr style={underlineStyles} />
      <div style={imageContainerStyles}>
        {props.videoUrl && (
          <video style={imageStyles} autoPlay playsInline loop muted>
            <source src={props.videoUrl} type="video/mp4" />
          </video>
        )}

        {props.imageUrls.map((url, idx) => (
          <img
            key={idx}
            src={url}
            style={imageStyles}
            alt={`${props.name} ${idx}`}
          />
        ))}
      </div>
      {props.description.map((desc, idx) => (
        <h4 key={idx} style={descriptionStyles}>
          {desc}
        </h4>
      ))}

      <h4 style={techStackStyles}>Tech Stack: {props.techStack}</h4>
      <div style={linkContainerStyles}>
        <h4 style={{ fontWeight: 400 }}>Link:</h4>
        {props.links.map((link, index) => (
          <>
            <a href={link.url} style={linkStyles}>
              {link.text}
            </a>
            {index < props.links.length - 1 && (
              <p style={{ marginLeft: 8 }}>/</p>
            )}
          </>
        ))}
      </div>
    </>
  );
}

export default function Projects() {
  return (
    <>
      <Project
        name={"Storymap"}
        logoUrl={storymapLogo}
        imageUrls={[
          storymapLogin,
          storymapHome,
          storymapNotifs,
          storymapComments,
        ]}
        description={[
          "Storymap is an online travel diary/social network that allows users to create, share, like, and comment on location based blog posts from around the world.",
          "This was a solo project ideated, designed and built by myself as a result of my passion for both travel and storytelling.",
        ]}
        techStack={
          "React, React Native, TypeScript, NodeJS, Express, PostgreSQL, Expo."
        }
        links={[
          { text: "storymap.co", url: "https://www.storymap.co/" },
          {
            text: "App Store",
            url: "https://apps.apple.com/us/app/storymap-travel-diary/id6504540953",
          },
        ]}
      />
      <Project
        name={"Sircles"}
        logoUrl={sirclesLogo}
        videoUrl={sirclesVideo}
        imageUrls={[sirclesHome, sirclesGameplay, sirclesBoosts]}
        description={[
          "Sircles is a mobile arcade game designed to test reflexes.  Aided by various boosts that can be earned through gameplay or purchased in app, the aim of the game is to destroy as many ‘sircles’ as they fall towards the bottom of the screen at exponentially increasing speeds.",
        ]}
        techStack={"C#, Unity."}
        links={[
          {
            text: "App Store",
            url: "https://apps.apple.com/app/sircles/id1403153810",
          },
        ]}
      />
    </>
  );
}
