import * as React from "react";
import { useState } from "react";
import CSS from "csstype";
import { COLORS } from "../styles";

const navBtnStyles: CSS.Properties = {
  marginRight: "10px",
  border: "none",
  textTransform: "uppercase",
  background: "none",
  cursor: "pointer",
  fontSize: "0.66em",
};

const navBtnHoverStyles: CSS.Properties = {
  textDecoration: "underline",
  textDecorationColor: COLORS.purple,
  textUnderlineOffset: "8px",
};

const navBtnActiveStyles: CSS.Properties = {
  color: COLORS.purple,
};

export default function NavButton(props: {
  section: string;
  isActive: boolean;
}) {
  const [hover, setHover] = useState("");
  return (
    <button
      key={props.section}
      onMouseEnter={() => {
        setHover(props.section);
      }}
      onMouseLeave={() => {
        setHover("");
      }}
      style={{
        ...navBtnStyles,
        ...(hover === props.section ? navBtnHoverStyles : null),
        ...(props.isActive ? navBtnActiveStyles : null),
      }}
    >
      {props.section}
    </button>
  );
}
