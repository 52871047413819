import * as React from "react";
import memoji from "../../images/memoji-main.png";

import CSS from "csstype";
import { useEffect, useState } from "react";
import { COLORS } from "../../styles";

const memojiContainerStyles: CSS.Properties = {
  display: "flex",
  width: "100%",
  justifyContent: "center",
};

const memojiStyles: CSS.Properties = {
  height: "10rem",
  borderRadius: "200px",
};

const textContainerStyles: CSS.Properties = {
  textAlign: "center",
};

const textStyles: CSS.Properties = {
  fontSize: "1.5em",
  fontWeight: 400,
};

const cursorStyles: CSS.Properties = {
  color: COLORS.purple,
};

const LINES_OF_TEXT = [
  "Hi,",
  "I’m Divine, Full-stack Senior Software Engineer.",
  " I'm excited about using technology to build products that enrich our lives.",
];

function AnimatedText(props: {
  text: string;
  startAnimation: boolean;
  onFinishAnimate: () => void;
  speed: number;
}) {
  const { text, startAnimation, onFinishAnimate, speed } = props;

  const [textSoFar, setTextSoFar] = useState("");
  const [index, setIndex] = useState(0);

  const [showCursor, setShowCursor] = useState(false);

  useEffect(() => {
    if (!startAnimation) {
      return;
    }
    if (index < text.length) {
      setShowCursor(true);
      setTimeout(() => {
        setTextSoFar(textSoFar + text[index]);
        setIndex(index + 1);
      }, speed);
    }
    if (index === text.length) {
      setShowCursor(false);
      onFinishAnimate();
    }
  }, [index, text, textSoFar, speed, startAnimation, onFinishAnimate]);
  return (
    <>
      <h3 style={textStyles}>
        {textSoFar}
        {showCursor && <span style={cursorStyles}>_</span>}
      </h3>
    </>
  );
}

export default function Intro() {
  const [lineToAnimate, setLineToAnimate] = useState(1000);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLineToAnimate(0);
    }, 1800);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleAnimationComplete = (index: number) => {
    setTimeout(() => {
      setLineToAnimate(index + 1);
    }, 300);
  };

  return (
    <>
      <div style={memojiContainerStyles}>
        <img
          src={memoji}
          style={memojiStyles}
          className={"fade-in"}
          alt="Divine Memoji"
        />
      </div>
      <div style={textContainerStyles}>
        {LINES_OF_TEXT.map((text, idx) => (
          <AnimatedText
            key={idx}
            text={text}
            startAnimation={idx === lineToAnimate}
            onFinishAnimate={() => handleAnimationComplete(idx)}
            speed={idx === 0 ? 100 : 20}
          />
        ))}
      </div>
    </>
  );
}
